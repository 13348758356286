@import "../../styles.base.scss";

@media (min-width: 768px) {
.privacyPolicySection::before {
  content: '';
  width: 50%;
  height: 80%;
  right: 20%;
  top: 20%;
  margin: 0 8rem;
  position: absolute;
  opacity: 0.15;
  background: url(../../assets/PrivacyPolicy.jpeg) no-repeat fixed;
  background-size: cover;
  transform: rotate(220deg);
}
}

.privacyPolicySection {
  margin-top: 7rem;
  .pageHeader {
    font-size: 2.5rem;
    text-align: center;
    background: linear-gradient(to bottom, rgb(102, 94, 94), white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  .contentWrapper {
    opacity: 0.75;
    color: white;
    width: 70%;
    font-size: 1.3rem;
    text-align: justify;
    margin: 4rem auto;
    margin-bottom: 6rem;
    @media (max-width: 768px) {
      width: 80%;
    }
    ul {
      padding-left: 1rem;
    }
    p {
      text-indent: 1rem;
      line-height: 2rem;
      @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
    li {
      line-height: 2.5rem;
      @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.5rem;
        padding-bottom: 1rem;
      }
    }
  }
  .sectionHeader {
    color: #128c7e;
    margin-top: 3rem;
    @media (max-width: 768px) {
      font-size: 1.25rem;
      text-align: initial;
    }
  }
  .supportLink {
    font-weight: bolder;
    text-decoration: none;
    color: #39b3ed;
  }
}
