@media (min-width: 768px) {
.contentWrapper::before {
  content: '';
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  opacity: 0.25;
  background: url(../../assets/FAQ.jpeg) no-repeat fixed;
  background-size: cover;
}
}

.contentWrapper {
  margin-top: 7rem;

  .pageHeader {
    font-size: 2.5rem;
    text-align: center;
    background: linear-gradient(to bottom, rgb(102, 94, 94), white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  .accordionSection {
    width: 70%;
    opacity: 0.7;
    margin: 5rem auto;
    @media (max-width: 767px){
      width: 90%;
    }
    :global(.ant-collapse .ant-collapse-header){
      font-family: system-ui;
      color: white;
      font-size: 1.2rem;
    }
    :global(.ant-collapse-content){
      font-size: 1.2rem;
      font-family: system-ui;
    }
  }
}
