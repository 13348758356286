@import "../../styles.base.scss";

@keyframes revealName {
  0%{
    width: 0rem;
  }
  50%{
    width: 12.5rem;
  }
  100%{
    width: 25rem;
  }
}

.introPage{
  background: white;
  position: relative;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2 ;
  display: flex;
  align-items: center;
  justify-content: center;
  .productName{
    font-size: 5rem;
    font-weight: bolder;
    width: 25rem;
    text-align: center;
    background: linear-gradient(
      to right,
      #fbc2eb,
      #a18cd1
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: revealName 0.5s 1 linear;
    @media (max-width: 400px){
      font-size: 4rem;
    }
  }
}
@media (min-width: 768px) {
.waitlistPage_background::before {
  content: '';
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  opacity: 0.15;
  background: $black url(../../assets/Waitlist.jpeg) no-repeat fixed;
  background-size: cover;
}
}

.waitlistPage_background {
  width: 100%;
  height: 100%;

  .productTitle {
    background: linear-gradient(
      to right,
      #fbc2eb,
      #a18cd1
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  .productSlogan {
    font-size: 3.5rem;
    @media (max-width: 768px){
      font-size: 3rem;
      margin-top: 8rem;
    }
    padding: 0 2rem;
    margin-top: 12rem;
    text-align: center;
    background: linear-gradient(to bottom, rgb(102, 94, 94), white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  .contentWrapper {
    @include flex();
    margin-top: 4rem;
    @media (max-width: 1024px){
      flex-direction: column;
      margin-top: 2rem;
    }
    .leftSection{
      opacity: 0.7;
      width: 65%;
      color: white;
      box-sizing: border-box;
      padding: 0 5rem;
      font-size: 1.2rem;
      line-height: 2.2rem;
      text-align: justify;
      p{
        padding-left: 1rem;
        font-weight: 500;
      }
      @media (max-width: 1024px){
        width: 100%;
      }
      @media (max-width: 768px){
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0 2.5rem;
      }
    }
    .rightSectionHeader{
      color: rgb(223, 204, 204);
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 2rem;
      @media (max-width: 1024px){
        margin-bottom: 2rem;
      }
    }

    .rightSection{
      width: 35%;
      @include flex();
      @media(max-width: 767px){
        margin-bottom: 4rem;
      }
      @media (max-width: 1024px){
        width: 100%;
        flex-direction: column;
      }
      .rightContent{
        width: 100%;
        opacity: 0.8;
        text-align: center;
      }
      :global(.ant-input-prefix){
        color: white;
        padding-right: 0.5rem;
      }
      :global(.ant-input-affix-wrapper){
        background-color: black;
        margin-bottom: 1.5rem;
        height: 3rem;
        width: 75%;
        input::placeholder{
          padding-top: 0.5rem;
          color: #B2BEB5;
        }
      }
      :global(.ant-input){
        background-color: black;
        color: white;
      }
      .joinButton{
        width: 75%;
        height: 3rem;
        font-size: large;
      }
    }
  }
}

.waitlistModal{
  border: 0.1rem solid white;
  border-radius: 2%;
  .modalContent{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 2rem;
    font-family: system-ui;
  }
  .waitlistDoneIcon{
    font-size: 3rem;
    color: green;
    margin-bottom: 2rem;
  }
  :global(.ant-modal-content){
    background: linear-gradient(to bottom, rgb(209, 206, 206), rgb(97, 95, 95));
  }
  .successMessage{
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  .infoMessage{
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .copyIcon{
    cursor: pointer;
  }
}