@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import './styles.base.scss';

body {
  margin: 0;
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $black;
}
