@import '../../styles.base.scss';

.navbar {
  background: $black;
  color: white;
  font-size: 1.4rem;
  border-bottom: 0.1rem solid rgb(87, 85, 85);
  z-index: 1;
  min-height: 4rem;
  width: 100%;
  position: fixed;
  top: 0;
  @media (min-width: 768px){
    height: 4rem;
  }
  @include flex($alignItems: center, $justifyContent: space-between);

  .productLogo{
    margin: 3rem 0 3rem 2rem;
    font-weight: bolder;
  }
  .navbarLinks{
    margin: 3rem 2rem 3rem 0;
  }
  .linkText{
    color: inherit;
    text-decoration: inherit;
  }
  a{
    padding-right: 1.5rem;
  }
}

.hambugerHeader{
  width: 100%;
  height: 4rem;
  top: 0;
  position: fixed;
  border-bottom: 0.1rem solid rgb(87, 85, 85);
  @include flex($alignItems: center, $justifyContent: space-between);
  .hamburgerIcons{
    padding-right: 2rem;
  }
}

.hamburgerMenus{
  display: flex;
  margin-top: 4rem;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  a{
    padding: 1rem 2rem;
    font-size: 1rem;
    border-bottom: 0.1rem dashed #128c7e;
  }
}