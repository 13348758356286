// Variables

// Colors

$white: #fff;
$black: #000;

// Mixins

@mixin flex($alignItems: center, $justifyContent: center){
    display: flex;
    align-items: $alignItems;
    justify-content: $justifyContent;
}